import React, { useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "@tecma/i18n";
import { Button, Card, Input } from "@tecma/ds";

import SimplePage from "components/common/SimplePage/SimplePage";
import AccountManagerDrawer from "components/AccountManagerDrawer/AccountManagerDrawer";

import "./account-manager.scss";

interface AccountManagerProps {
  className?: string;
  id?: string;
  style?: React.CSSProperties;
}

const AccountManager: React.FC<AccountManagerProps> = observer(() => {
  const { t } = useTranslation();
  const [isAddAccountMenuOpen, setIsAddAccountMenuOpen] = useState(false);

  return (
    <>
      <SimplePage
        title={t("iTd.accountManager.title")}
        description={t("iTd.accountManager.description")}
      >
        <div className="account-manager">
          <Card fluid>
            <Card.Header>
              <div className="header-content">
                <p className="title">{t("iTd.accountManager.searchAccount")}</p>
                <p className="description">
                  {t("iTd.accountManager.searchAccount.description")}
                </p>
              </div>
              <Button
                iconName="plus"
                onClick={() => setIsAddAccountMenuOpen(true)}
                color="transparent"
              >
                {t("iTd.accountManager.addAccount")}
              </Button>
            </Card.Header>
            <Card.Content className="content">
              <Input
                label={t("iTd.accountManager.field.email.label")}
                placeholder={t("iTd.accountManager.field.email.placeholder")}
                onChange={() => {}}
              />
              <Button onClick={() => {}} iconName="search">
                {t("iTd.genericTable.search")}
              </Button>
            </Card.Content>
          </Card>
        </div>
      </SimplePage>
      <AccountManagerDrawer
        isOpen={isAddAccountMenuOpen}
        setIsOpen={setIsAddAccountMenuOpen}
      />
    </>
  );
});

export default React.memo(AccountManager);
