import { gql } from "@apollo/client";
import TableSearchingType from "../types/TableSearchingType";
import TableSortingType from "../types/TableSortingType";

const BROKER_DATA_TO_RETRIEVE = `
      id
      name
      parent_broker
      createdOn
      baseUrl
      brokerFlags {
        report
      }
      brokers {
        id
        name
        parent_broker
        url
        brokerFlags {
          report
        }
      }
      projects {
        id
        name
        displayName
        enabledTools
        isCommercialDemo
        broker
        storeAddress
      }
      iTdConfig
`;

const USER_DATA_TO_RETRIEVE = `
      id
      firstName
      lastName
      email
      project_ids
      language
      role
      hasGoogleToken
      hasMicrosoftToken
      vendorColor
      TwoFA
      locale
`;

const PROMO_PRICE_DATA_TO_RETRIEVE = `
      apartment
      status
      price
      promoPrice
      project_id
      typology
`;

const FEATURES_DATA_TO_RETRIEVE = `
        total
        elements
`;

const CLIENTS_COUNT_BY_STATUS_DATA_TO_RETRIEVE = `
      lead
      prospect
      client
      callBack
`;

const ACCESS_INFORMATION_DATA_TO_RETRIEVE = `
      typology
      number
`;
const PAGED_APARTMENTS_DATA_TO_RETRIVE = `
  page
  perPage
  total
  numberOfPages
  elements
`;
const PAGED_CLIENTS_DATA_TO_RETRIVE = `
  page
  perPage
  total
  numberOfPages
  elements
`;

const PRODUCT_DATA_TO_RETRIEVE = `
    name
    label
    title
    default
    roles
`;

const GET_BROKER_INFO = gql`
query getBrokerInfoByHost ($host :String!, $url: String) {
  getBrokerInfoByHost (host :$host, url:$url) {
    ${BROKER_DATA_TO_RETRIEVE}
  }
}`;

const GET_BROKER_INFO_DEFAULT_OPTIONS = (hostname: string | undefined) => {
  return {
    variables: {
      host: hostname,
      url: window.location.href,
    },
  };
};

const GET_USER_INFO = gql`
  query getUserByJWT($project_id: ID!) {
    getUserByJWT(project_id: $project_id) {
      ${USER_DATA_TO_RETRIEVE}
    }
  }
`;

const GET_USER_INFO_DEFAULT_OPTIONS = (
  pId: string | undefined,
  skipCondition: boolean,
) => {
  return {
    variables: {
      project_id: pId,
    },
    skip: skipCondition,
  };
};

const GET_USER_INFO_DEFAULT_OPTIONS_V2 = (
  pId: string | undefined,
  skipCondition: boolean,
) => {
  return {
    variables: {
      project_id: pId,
    },
    skip: skipCondition,
  };
};

const GET_APARTMENTS_SOLD_COUNT = gql`
  query getApartmentSoldCount($project_ids: [ID!]) {
    getApartmentSoldCount(project_ids: $project_ids)
  }
`;

const GET_APARTMENTS_SOLD_COUNT_DEFAULT_OPTIONS = (
  project_ids: Array<string>,
) => {
  return {
    variables: {
      project_ids,
    },
  };
};

const GET_APARTMENTS_COUNT = gql`
  query getApartmentsCount($project_ids: [ID!]) {
    getApartmentsCount(project_ids: $project_ids)
  }
`;

const GET_APARTMENTS_COUNT_DEFAULT_OPTIONS = (project_ids: Array<string>) => {
  return {
    variables: {
      project_ids,
    },
  };
};

const GET_ALL_FEATURES_ITD = gql`
  query getAllFeaturesITd($project_ids: [ID!], $page: Int, $perPage: Int) {
    getAllFeaturesITd(project_ids: $project_ids, page: $page, perPage: $perPage){
      ${FEATURES_DATA_TO_RETRIEVE}
    }
  }
`;

const GET_ALL_FEATURES_ITD_DEFAULT_OPTIONS = (
  project_ids: Array<string>,
  page?: number,
  perPage?: number,
) => {
  return {
    variables: {
      project_ids,
      page,
      perPage,
    },
  };
};

const GET_ALL_APARTMENTS_ITD = gql`
  query getAllApartmentsITd($project_ids: [ID!], $page: Int, $perPage: Int, $sorting: [MultiProjectApartmentSort], $searching: [MultiProjectApartmentSearch] ) {
    getAllApartmentsITd(
      project_ids: $project_ids
      page: $page
      perPage: $perPage
      sorting: $sorting
      searching: $searching
    ){
      ${PAGED_APARTMENTS_DATA_TO_RETRIVE}
    }
  }
`;

const GET_ALL_APARTMENTS_ITD_DEFAULT_OPTIONS = (
  project_ids: Array<string>,
  page: number,
  perPage: number,
  sorting?: TableSortingType[],
  searching?: TableSearchingType[],
) => {
  return {
    variables: {
      project_ids,
      page,
      perPage,
      sorting,
      searching,
    },
  };
};

const GET_ALL_APARTMENTS_ITD_2 = gql`
  query getAllApartmentsITd2($project_ids: [ID!], $page: Int, $perPage: Int) {
    getAllApartmentsITd2(
      project_ids: $project_ids
      page: $page
      perPage: $perPage
    )
  }
`;

const GET_ALL_APARTMENTS_ITD_2_DEFAULT_OPTIONS = (
  project_ids: Array<string>,
  page: number,
  perPage: number,
) => {
  return {
    variables: {
      project_ids,
      page,
      perPage,
    },
  };
};

const GET_MANAGED_APPOINTMENTS_COUNT = gql`
  query getManagedAppointmentCount($project_ids: [ID!]) {
    getManagedAppointmentCount(project_ids: $project_ids)
  }
`;

const GET_MANAGED_APPOINTMENTS_COUNT_DEFAULT_OPTIONS = (
  project_ids: Array<string>,
) => {
  return {
    variables: {
      project_ids,
    },
  };
};

const GET_CALENDAR_APPOINTMENTS_COUNT = gql`
  query getCalendarAppointmentCounts($project_ids: [ID!]) {
    getCalendarAppointmentCounts(project_ids: $project_ids) {
      managed
      canceled
    }
  }
`;

const GET_CALENDAR_APPOINTMENTS_COUNT_DEFAULT_OPTIONS = (
  project_ids: Array<string>,
) => {
  return {
    variables: {
      project_ids,
    },
  };
};

const GET_ACCESS_INFORMATION = gql`
  query getAccessInformation($project_ids: [ID!]) {
    getAccessInformation(project_ids: $project_ids){
      ${ACCESS_INFORMATION_DATA_TO_RETRIEVE}
    }
  }
`;

const GET_ACCESS_INFORMATION_DEFAULT_OPTIONS = (project_ids: Array<string>) => {
  return {
    variables: {
      project_ids,
    },
  };
};

const GET_CLIENTS_COUNT = gql`
  query getClientCount($project_ids: [ID!]) {
    getClientCount(project_ids: $project_ids)
  }
`;

const GET_CLIENTS_COUNT_DEFAULT_OPTIONS = (project_ids: Array<string>) => {
  return {
    variables: {
      project_ids,
    },
  };
};

const GET_ALL_TYPOLOGIES_MULTI_PROJECT = gql`
  query getAllTypologiesMultiProject(
    $project_ids: [ID!]
    $page: Int
    $perPage: Int
  ) {
    getAllTypologiesMultiProject(
      project_ids: $project_ids
      page: $page
      perPage: $perPage
    ) {
      elements
    }
  }
`;

const GET_ALL_TYPOLOGIES_MULTI_PROJECT_DEFAULT_OPTIONS = (
  project_ids: Array<string>,
  page?: number,
  perPage?: number,
) => {
  return {
    variables: {
      project_ids,
      page,
      perPage,
    },
  };
};

const GET_ALL_CLIENTS = gql`
  query getAllClients($project_ids: [ID!], $page: Int, $perPage: Int, $sorting: [MultiProjectClientSort], $searching: [MultiProjectClientSearch]) {
    getAllClients(project_ids: $project_ids, page: $page, perPage: $perPage, sorting: $sorting, searching: $searching){
      ${PAGED_CLIENTS_DATA_TO_RETRIVE}
    }
  }
`;

const GET_ALL_CLIENTS_DEFAULT_OPTIONS = (
  project_ids: Array<string>,
  page: number,
  perPage: number,
  sorting?: TableSortingType[],
  searching?: TableSearchingType[],
) => {
  return {
    variables: {
      project_ids,
      page,
      perPage,
      sorting,
      searching,
    },
  };
};

const GET_CLIENTS_COUNT_BY_STATUS = gql`
  query getClientCountByStatus($project_ids: [ID!]) {
    getClientCountByStatus(project_ids: $project_ids){
      ${CLIENTS_COUNT_BY_STATUS_DATA_TO_RETRIEVE}
    }
  }
`;

const GET_CLIENTS_COUNT_BY_STATUS_DEFAULT_OPTIONS = (
  project_ids: Array<string>,
) => {
  return {
    variables: {
      project_ids,
    },
  };
};

const GET_PROMO_PRICE = gql`
  query getPromoPrice($project_ids: [ID!]) {
    getPromoPrice(project_ids: $project_ids){
      ${PROMO_PRICE_DATA_TO_RETRIEVE}
    }
  }
`;

const GET_PROMO_PRICE_DEFAULT_OPTIONS = (project_ids: Array<string>) => {
  return {
    variables: {
      project_ids,
    },
  };
};

const UPDATE_USER = gql`
  mutation updateUsers( $id: ID!, $input: UserInput!) {
    updateUsers( id: $id, input: $input) {
      ${USER_DATA_TO_RETRIEVE}
    }
  }
`;

const UPDATE_USER_DEFAULT_OPTIONS = (
  id: string | undefined,
  input: unknown,
) => {
  return {
    variables: {
      id,
      input,
    },
  };
};

const GET_PROJECT_TOOL_STATUS = gql`
  query getProjectToolStatus($project_id: ID!) {
    getProjectToolStatus(id: $project_id) {
      status
      toolName
    }
  }
`;

const GET_PROJECT_TOOL_STATUS_DEFAULT_OPTIONS = (pId: string) => {
  return {
    variables: {
      project_id: pId,
    },
  };
};

const GET_ALL_PRODUCTS = gql`
query getAllProducts($project_id :ID!){
  getAllProducts(project_id :$project_id){
    ${PRODUCT_DATA_TO_RETRIEVE}
  }
}`;

const GET_ALL_PRODUCTS_DEFAULT_OPTIONS = (pId: string) => {
  return {
    variables: {
      project_id: pId,
    },
  };
};

const MUTATION_LOGOUT = gql`
  mutation logout {
    logout
  }
`;

const Client = {
  GET_USER_INFO,
  GET_USER_INFO_DEFAULT_OPTIONS,
  GET_USER_INFO_DEFAULT_OPTIONS_V2,
  GET_BROKER_INFO,
  GET_BROKER_INFO_DEFAULT_OPTIONS,
  GET_APARTMENTS_SOLD_COUNT,
  GET_APARTMENTS_SOLD_COUNT_DEFAULT_OPTIONS,
  GET_APARTMENTS_COUNT,
  GET_APARTMENTS_COUNT_DEFAULT_OPTIONS,
  GET_ALL_FEATURES_ITD,
  GET_ALL_FEATURES_ITD_DEFAULT_OPTIONS,
  GET_ALL_APARTMENTS_ITD,
  GET_ALL_APARTMENTS_ITD_DEFAULT_OPTIONS,
  GET_ALL_APARTMENTS_ITD_2,
  GET_ALL_APARTMENTS_ITD_2_DEFAULT_OPTIONS,
  GET_MANAGED_APPOINTMENTS_COUNT,
  GET_MANAGED_APPOINTMENTS_COUNT_DEFAULT_OPTIONS,
  GET_CALENDAR_APPOINTMENTS_COUNT,
  GET_CALENDAR_APPOINTMENTS_COUNT_DEFAULT_OPTIONS,
  GET_ACCESS_INFORMATION,
  GET_ACCESS_INFORMATION_DEFAULT_OPTIONS,
  GET_CLIENTS_COUNT,
  GET_CLIENTS_COUNT_DEFAULT_OPTIONS,
  GET_ALL_TYPOLOGIES_MULTI_PROJECT,
  GET_ALL_TYPOLOGIES_MULTI_PROJECT_DEFAULT_OPTIONS,
  GET_ALL_CLIENTS,
  GET_ALL_CLIENTS_DEFAULT_OPTIONS,
  GET_CLIENTS_COUNT_BY_STATUS,
  GET_CLIENTS_COUNT_BY_STATUS_DEFAULT_OPTIONS,
  GET_PROMO_PRICE,
  GET_PROMO_PRICE_DEFAULT_OPTIONS,
  UPDATE_USER,
  UPDATE_USER_DEFAULT_OPTIONS,
  GET_PROJECT_TOOL_STATUS,
  GET_PROJECT_TOOL_STATUS_DEFAULT_OPTIONS,
  GET_ALL_PRODUCTS,
  GET_ALL_PRODUCTS_DEFAULT_OPTIONS,
  MUTATION_LOGOUT,
};

export default Client;
