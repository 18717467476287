import ButtonBase from "@mui/material/ButtonBase";
import { useTranslation } from "@tecma/i18n";
import { memo } from "react";

const SupportMenuButton: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ButtonBase
      className="user-menu-button"
      disableRipple
      onClick={(e) => {
        window.location.href = `mailto:${process.env.REACT_APP_INFO_MAIL}`;
        e.preventDefault();
      }}
    >
      {t("iTd.navBar.support")}
    </ButtonBase>
  );
};

export default memo(SupportMenuButton);
