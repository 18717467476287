import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "@tecma/i18n";
import { Button, Drawer, Input, PhoneInput, Select } from "@tecma/ds";
import {
  PhoneCountry,
  getCountryAndPrefix,
  isValidNumber,
} from "@tecma/ds/lib/components/PhoneInput/utils/functions";
import { Locales } from "@tecma/ds/lib/components/PhoneInput/utils/locales";
import type { OptionSelect } from "@tecma/ds/lib/components/Select/Select";

import {
  ACCOUNT_MANAGER_ROLE,
  BUILDING_MANAGER_ROLE,
  FRONT_OFFICE_ROLE,
  PROPRIETA_ROLE,
  VENDOR_MANAGER_ROLE,
  VENDOR_ROLE,
} from "constants/role";
import { useStore } from "store/storeUtils";

import "./account-manager-drawer.scss";

interface AccountFieldErrors {
  name?: string;
  surname?: string;
  email?: string;
  phone?: string;
  language?: string;
  role?: string;
  assets?: string;
}

interface AccountFields {
  name?: string;
  surname?: string;
  email?: string;
  phone?: string;
  language?: OptionSelect;
  role?: OptionSelect;
  assets?: OptionSelect[];
}

interface AccountManagerDrawerProps extends AccountFields {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  accountFields?: AccountFields;
}

const roleOptions: OptionSelect[] = [
  {
    label: "Account Manager",
    value: ACCOUNT_MANAGER_ROLE,
  },
  {
    label: "Vendor",
    value: VENDOR_ROLE,
  },
  {
    label: "Vendor Manager",
    value: VENDOR_MANAGER_ROLE,
  },
  {
    label: "Visitor",
    value: PROPRIETA_ROLE,
  },
  {
    label: "Front Office",
    value: FRONT_OFFICE_ROLE,
  },
  {
    label: "Facility Manager",
    value: BUILDING_MANAGER_ROLE,
  },
];

const emptyAccountFields: AccountFields = {
  name: "",
  surname: "",
  email: "",
  phone: "",
  language: undefined,
  role: undefined,
  assets: [],
};

const EMAIL_REGEX =
  /^(?!.*\.\.)(?!.*\s)(?!^\.)(?!.*\.$)(?!^@)(?!.*@.*@)[\p{L}\p{N}_.+-]+@(?:[a-zA-Z0-9-]+\.[a-zA-Z]{2,}|(?:\[(?:\d{1,3}\.){3}\d{1,3}\])|(?:xn--[a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*))$/u;
const NAME_REGEX = /^[\s\S]*$/;
const getFieldMaxLength = (field: keyof AccountFields) => {
  if (field === "email") {
    return 40;
  }
  return 50;
};
// TODO: use if want prefix
// const getCountryPrefix = (countryLabel: string) => {
//   return `+${countryLabel.split("+")[1]}`;
// };

const AccountManagerDrawer: React.FC<AccountManagerDrawerProps> = observer(
  ({ isOpen, setIsOpen, accountFields }) => {
    const { i18n, t } = useTranslation();
    const store = useStore();
    const [fields, setFields] = useState<AccountFields>(
      accountFields || emptyAccountFields,
    );
    const [fieldErrors, setFieldErrors] = useState<AccountFieldErrors>();
    const initialPhoneCountry = getCountryAndPrefix(
      (i18n.language.split("-")[1] || "IT") as PhoneCountry,
      i18n.language.split("-")[0] as Locales,
    );
    const [country, setCountry] = useState<OptionSelect>(initialPhoneCountry);
    const languageOptions: OptionSelect[] =
      store.languages?.map((lang) => ({
        label: t(`iTd.language.${lang}`),
        value: lang,
      })) || [];

    const getFieldError = (field: keyof AccountFields) => {
      if (fieldErrors && fieldErrors[field]) {
        return t(`iTd.field.error.${fieldErrors[field]}`, {
          maxLength: getFieldMaxLength(field),
          field: t(`iTd.accountManager.field.${field}`).toLocaleLowerCase(),
        });
      }
      return null;
    };

    // Validators
    const isFieldEmpty = (field: string) => {
      const currField = fields[field as keyof AccountFields];
      if (
        (typeof currField === "string" && !currField.trim()) ||
        !currField ||
        (Array.isArray(currField) && !currField?.length)
      ) {
        setFieldErrors((err) => ({ ...err, [field]: "isRequired" }));
        return true;
      }
      return false;
    };
    const isMaxLengthExceeded = (
      field: string,
      currLength: number,
      maxLength: number,
    ) => {
      if (currLength > maxLength) {
        setFieldErrors((err) => ({ ...err, [field]: "exceedMaxLength" }));
        return true;
      }
      return false;
    };
    const isFormatInvalid = (field: keyof AccountFields, regex: RegExp) => {
      const value = (fields[field] || "") as string;
      if (!regex.test(value)) {
        setFieldErrors((err) => ({ ...err, [field]: "invalidFormat" }));
        return true;
      }
      return false;
    };
    const isValidPhoneNumber = (phoneCountry: string, phone?: string) => {
      const isValid = isValidNumber(
        phoneCountry as PhoneCountry,
        phone || fields.phone || "",
      );
      if (!isValid) {
        setFieldErrors((err) => ({ ...err, phone: "invalidFormat" }));
      }
      return isValid;
    };
    const hasFormErrors = () => {
      if (fieldErrors) {
        return Object.keys(fieldErrors).some(
          (error) => fieldErrors[error as keyof AccountFieldErrors],
        );
      }
      return false;
    };

    // Handlers
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      let error = false;
      if (name === "name" || name === "surname") {
        error = isMaxLengthExceeded(
          name,
          value.length,
          getFieldMaxLength(name),
        );
      } else if (name === "email") {
        error = isMaxLengthExceeded(
          name,
          value.length,
          getFieldMaxLength(name),
        );
      } else if (name === "phone") {
        error = !isValidPhoneNumber(country.value as PhoneCountry, value);
      }
      setFields((prevFields) => ({
        ...prevFields,
        [name]: value,
      }));
      if (!error) {
        if (fieldErrors && fieldErrors[name as keyof AccountFields]) {
          setFieldErrors((err) => ({ ...err, [name]: null }));
        }
      }
    };
    const handleSelectChange = (field: string, value: OptionSelect) => {
      setFields((prevFields) => ({
        ...prevFields,
        [field]: value,
      }));
      if (value) {
        setFieldErrors((err) => ({ ...err, [field]: null }));
      }
    };
    const handleOnUpdateFilters = (filters: OptionSelect[] | any) => {
      setFields((prevFields) => ({
        ...prevFields,
        assets: filters,
      }));
      if (filters?.length) {
        setFieldErrors((err) => ({ ...err, assets: undefined }));
      }
    };
    const handleSubmit = () => {
      let errors = {};
      Object.keys(fields).forEach((field) => {
        if (field !== "phone" && isFieldEmpty(field)) {
          errors = { ...errors, [field]: "isRequired" };
        }
      });
      if (Object.keys(errors).length > 0) {
        setFieldErrors((currErrors) => ({ ...currErrors, ...errors }));
      } else if (!hasFormErrors()) {
        setIsOpen(false);
        alert(
          `ACCOUNT DATA:
            name: ${fields.name?.trim()}
            surname: ${fields.surname?.trim()}
            email: ${fields.email?.toLowerCase()}
            phone: ${fields.phone}
            language: ${fields.language?.value}
            role: ${fields.role?.value}
            assets: [${fields.assets?.map((asset) => `${asset.value}`)}]
        `,
        );
      }
    };
    const handleChangeCountry = (newValue: OptionSelect | OptionSelect[]) => {
      if (fields.phone) {
        const isValid = isValidPhoneNumber((newValue as OptionSelect).value);
        if (isValid && fieldErrors?.phone) {
          setFieldErrors((err) => ({ ...err, phone: undefined }));
        }
      }
      setCountry(newValue as OptionSelect);
    };

    useEffect(() => {
      if (isOpen && !accountFields?.assets) {
        setFields((currFields) => ({
          ...currFields,
          assets: store.projectsFilterOptions ?? [],
        }));
      }
      if (fieldErrors) {
        setFieldErrors(undefined);
      }
      if (!isOpen) {
        setCountry(initialPhoneCountry);
        if (fields) {
          setFields(emptyAccountFields);
        }
      }
    }, [isOpen]);

    return (
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="account-manager-drawer"
      >
        <Drawer.Header
          label={t("iTd.accountManager.addAccount")}
          onClose={() => setIsOpen(false)}
        />
        <Drawer.Content>
          <Input
            label={t("iTd.accountManager.field.name")}
            placeholder={t("iTd.accountManager.field.name.placeholder")}
            value={fields.name}
            onChange={handleChange}
            onBlur={() => {
              if (!isFieldEmpty("name")) {
                isFormatInvalid("name", NAME_REGEX);
              }
            }}
            required
            name="name"
            status={fieldErrors?.name ? "error" : undefined}
            helpText={getFieldError("name")}
          />
          <Input
            label={t("iTd.accountManager.field.surname")}
            placeholder={t("iTd.accountManager.field.surname.placeholder")}
            value={fields.surname}
            onChange={handleChange}
            onBlur={() => {
              if (!isFieldEmpty("surname")) {
                isFormatInvalid("surname", NAME_REGEX);
              }
            }}
            required
            name="surname"
            status={fieldErrors?.surname ? "error" : undefined}
            helpText={getFieldError("surname")}
          />
          <Input
            label={t("iTd.accountManager.field.email")}
            placeholder={t("iTd.accountManager.field.email.placeholder")}
            value={fields.email}
            onChange={handleChange}
            onBlur={() => {
              if (!isFieldEmpty("email")) {
                isFormatInvalid("email", EMAIL_REGEX);
              }
            }}
            required
            name="email"
            status={fieldErrors?.email ? "error" : undefined}
            helpText={getFieldError("email")}
          />
          {country && (
            <PhoneInput
              label={t("iTd.accountManager.field.phone")}
              placeholder={t("iTd.accountManager.field.phone.placeholder")}
              value={fields.phone}
              name="phone"
              currentLanguage={i18n.language.split("-")[0] as Locales}
              selectedCountry={country}
              onChangeCountry={handleChangeCountry}
              onChange={handleChange}
              status={fieldErrors?.phone ? "error" : undefined}
              helpText={getFieldError("phone")}
              extraLabel={`(${t("iTd.field.optional")})`}
            />
          )}
          <Select
            label={t("iTd.accountManager.field.language")}
            placeholder={t("iTd.accountManager.field.language.placeholder")}
            value={fields.language as OptionSelect}
            options={languageOptions}
            onChange={(value) =>
              handleSelectChange("language", value as OptionSelect)
            }
            onMenuClose={() => isFieldEmpty("language")}
            closeMenuOnSelect
            isRequired
            error={!!fieldErrors?.language}
            helpText={getFieldError("language")}
            menuPlacement="auto"
          />
          <Select
            label={t("iTd.accountManager.field.role")}
            placeholder={t("iTd.accountManager.field.role.placeholder")}
            value={fields.role as OptionSelect}
            options={roleOptions}
            onChange={(value) =>
              handleSelectChange("role", value as OptionSelect)
            }
            onMenuClose={() => isFieldEmpty("role")}
            closeMenuOnSelect
            isRequired
            error={!!fieldErrors?.role}
            helpText={getFieldError("role")}
            menuPlacement="auto"
          />
          <Select
            isMulti
            value={fields.assets as OptionSelect[]}
            isRequired
            helpText={getFieldError("assets")}
            isLoading={!store.projectsFilterOptions?.length}
            options={store.projectsFilterOptions ?? []}
            placeholder={t("iTd.reports.initiative.placeholder")}
            label={t("iTd.reports.initiative.label")}
            noOptionsMessage={t("iTd.reports.initiative.noOptions")}
            searchPlaceholder={t("iTd.reports.initiative.search")}
            selectAllLabel={t("iTd.reports.initiative.selectAll")}
            deselectAllLabel={t("iTd.reports.initiative.deselectAll")}
            onChange={handleOnUpdateFilters}
            onMenuClose={() => isFieldEmpty("assets")}
            showSelectedItemRemoveIcon={false}
            error={!!fieldErrors?.assets}
            menuPlacement="auto"
          />
        </Drawer.Content>
        <Drawer.Footer>
          <Button onClick={() => setIsOpen(false)} color="secondary">
            {t("iTd.accountManager.cancel")}
          </Button>
          <Button onClick={handleSubmit}>{t("iTd.accountManager.add")}</Button>
        </Drawer.Footer>
      </Drawer>
    );
  },
);

export default AccountManagerDrawer;
