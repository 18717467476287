import ButtonBase from "@mui/material/ButtonBase";
import { useStore } from "store/storeUtils";
import { useTranslation } from "@tecma/i18n";
import { memo } from "react";

const LogoutMenuButton: React.FC = () => {
  const store = useStore();
  const { t } = useTranslation();

  const logOutHandler = () => {
    store.forceLogout(true); // prima passa per il componente "LogoutListener" per la pulizia dello store e poi avverrà il reindirizzamento
  };

  return (
    <ButtonBase
      disableRipple
      className="user-menu-button"
      onClick={logOutHandler}
    >
      {t("iTd.navBar.sign")}
    </ButtonBase>
  );
};

export default memo(LogoutMenuButton);
