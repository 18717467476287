/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable prettier/prettier */
import React, { forwardRef } from "react";
import MaterialTable from "material-table";

import "./genericTable.scss";
import {
  FirstPage,
  LastPage,
  ChevronRight,
  ChevronLeft,
  Clear,
  Search,
  ArrowDownward,
} from "@mui/icons-material";
import { useTranslation } from "@tecma/i18n";
import CustomPagination from "./CustomPagination";
import Loader from "../../Loader/Loader";
import TableToolbar from "./TableToolbar";
import CustomNoResults from "./CustomNoResults";

interface GenericTableProps {
  title?: any;
  columns?: any;
  data?: any;
  pageSizeOptions?: number[];
  paging?: boolean;
  searchText?: string;
  pageSize?: number;
  containerClass?: string;
  search?: boolean;
  exportButton?: boolean;
  saveSearch?: string;
  exportFileName?: string;
  filtering?: boolean;
  orderBy?: number;
}

const defaultProps: GenericTableProps = {
  pageSize: 5,
};

const GenericTable: React.FC<GenericTableProps> = ({
  title,
  columns,
  data,
  pageSizeOptions,
  paging,
  searchText,
  pageSize,
  search,
  exportButton,
  saveSearch,
  filtering,
  orderBy,
  exportFileName,
  ...rest
}) => {
  let customStyle = {};

  customStyle = { ...customStyle, position: "relative", bottom: "-15px" };

  const finalOptions = {
    sorting: true,
    search,
    searchText,
    emptyRowsWhenPaging: false,
    showEmptyDataSourceMessage: true,
    paging: paging && data && data.length > 0,
    rowStyle: {
      height: "80px",
    },
    // eslint-disable-next-line object-shorthand
    pageSize: pageSize || 5,
    pageSizeOptions: pageSizeOptions || [5, 10, 25, 50],
    exportFileName,
    exportButton,
    exportAllData: exportButton,
    filtering,
    searchFieldStyle: customStyle,
  };
  const { t } = useTranslation();

  const template = <CustomNoResults />;

  return (
    <div className="generic-table">
      <MaterialTable
        title={title}
        columns={columns}
        data={data}
        components={{
          Pagination: (props) => {
            return <CustomPagination {...props} />;
          },
          Toolbar: (props) => {
            return <TableToolbar title={title} {...props} />;
          },
          OverlayLoading: () => (
            <div className="generic-table-loader">
              <Loader vAlign="center" hAlign="center" />
            </div>
          ),
        }}
        options={finalOptions}
        icons={{
          FirstPage: forwardRef((props, ref) => (
            <FirstPage {...props} ref={ref} />
          )),
          LastPage: forwardRef((props, ref) => (
            <LastPage {...props} ref={ref} />
          )),
          NextPage: forwardRef((props, ref) => (
            <ChevronRight {...props} ref={ref} />
          )),
          PreviousPage: forwardRef((props, ref) => (
            <ChevronLeft {...props} ref={ref} />
          )),
          ResetSearch: forwardRef((props, ref) => (
            <Clear {...props} ref={ref} />
          )),
          Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
          SortArrow: forwardRef((props, ref) => (
            <ArrowDownward {...props} ref={ref} />
          )),
        }}
        localization={{
          body: {
            emptyDataSourceMessage: template,
          },
          toolbar: {
            searchTooltip: t("iTd.search"),
            searchPlaceholder: t("iTd.search"),
          },
          pagination: {
            labelDisplayedRows: t("iTd.displayedRows"),
            labelRowsSelect: t("iTd.rows"),
            labelRowsPerPage: t("iTd.rowsPerPage"),
            firstAriaLabel: t("iTd.firstPageAriaLabel"),
            firstTooltip: t("iTd.firstPageTooltip"),
            previousAriaLabel: t("iTd.previousPageAriaLabel"),
            previousTooltip: t("iTd.previousPageTooltip"),
            nextAriaLabel: t("iTd.nextPageAriaLabel"),
            nextTooltip: t("iTd.nextPageTooltip"),
            lastAriaLabel: t("iTd.lastPageAriaLabel"),
            lastTooltip: t("iTd.lastPageTooltip"),
          },
        }}
      />
    </div>
  );
};

GenericTable.defaultProps = defaultProps;

export default React.memo(GenericTable);
